





















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({ name: 'ArDownloadMore' })
export default class ArDownloadMore extends Vue {
    @Prop({default: 1}) readonly loadedCount: number;
    @Prop({default: 15}) readonly totalCount: number;

    @Emit('load-more')
    private loadMore() {
        return true;
    }
}
