







import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'SearchResultEmpty' })
export default class SearchResultEmpty extends Vue {}
