













import { Component, Prop, Vue } from 'vue-property-decorator';
import FullDoc from '@/components/block/full-doc.vue';
import {
    defaultFullDocumentList,
    FullDocument,
} from '@/types/front-data-types/full-docs';

@Component({
    name: 'FullDocs',
    components: {
        FullDoc,
    },
})
export default class FullDocs extends Vue {
    @Prop({ default: false }) readonly isRead: boolean;
    @Prop({ default: defaultFullDocumentList })
    readonly listData!: FullDocument[];
    @Prop({ default: false }) readonly noProjectName: boolean;
    @Prop({ default: false }) readonly noMaterialName: boolean;
}
