






import { Component, Prop, Vue } from 'vue-property-decorator';
import BookCard from '@/components/block/book-card.vue';
import { defaultBookList, BookList } from '@/types/front-data-types/book';

@Component({
    name: 'BookCards',
    components: {
        BookCard,
    },
})
export default class BookCards extends Vue {
    @Prop({ default: defaultBookList })
    readonly listData!: BookList;
    @Prop({ default: 4 })
    readonly columnCount: number;
}
