










































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

@Component({ name: 'ArSelectSort' })
export default class ArSelectSort extends Vue {
    @Getter('Search/SEARCH_VALUE')
    private SEARCH_VALUE: string;
    @Getter('Sort/SORT_VALUE')
    private SORT_VALUE: string;

    @Mutation('Sort/SET_SORT_VALUE')
    public SET_SORT_VALUE!: (value: string) => void;

    private optionListIsOpen = false;

    get optionList(): string[] {
        return [
            'За датою',
            'За алфавітом',
            'Спочатку — в назві документу',
            'Спочатку — в тексті документу',
            'Спочатку — в назві чи описі книги',
        ];
    }

    public setSortValue(value: string): void {
        this.SET_SORT_VALUE(value);
    }
}
