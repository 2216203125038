





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { FullDocument } from '@/types/front-data-types/full-docs';
import { Action } from 'vuex-class';

@Component({ name: 'ArButtonDownload' })
export default class ArButtonDownload extends Vue {
    @Prop({ default: '' })
    readonly src: string;
    @Prop({ default: '' })
    readonly fileName: string;
    @Prop({ default: false })
    readonly urlType: boolean;
    @Prop({ default: true })
    readonly isSimplifyVisual: boolean;
    @Prop({ default: false })
    readonly isBookCard: boolean;

    @Prop({ default: null })
    readonly document: FullDocument | null;

    @Action('DownloadPopup/OPEN_POPUP')
    public OPEN_POPUP!: (payload: FullDocument | null) => void;

    get url(): string {
        return `${this.src}`;
    }

    get docName(): string {
        return `${this.docName}`;
    }

    downloadCounter(): void {
        if (this.document?.extraDocs?.length !== 0) {
            this.OPEN_POPUP(this.document);
        } else {
            this.$emit('downloadCounter');
        }
    }

    created(): void {
        console.log('type', this.urlType);
    }
}
