






































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    defaultFullDocument,
    FullDocument,
} from '@/types/front-data-types/full-docs';
import { Action, Getter } from 'vuex-class';

import ArButtonDocumentChoose from '@/components/form/ar-button-document-choose.vue';
import ArButtonDownload from '@/components/form/ar-button-download.vue';
import BookPoster from '@/components/block/book-poster.vue';
import graphql from '@/plugins/graphql';
import { UpdateDocumentRequestType } from '@/types/requests/documents';
import UpdateDocumentDownloads from '@/graphql/documents/UpdateDocumentDownloads.gql';

@Component({
    name: 'BookCard',
    components: {
        ArButtonDocumentChoose,
        ArButtonDownload,
        BookPoster,
    },
})
export default class BookCard extends Vue {
    @Prop({ default: defaultFullDocument }) readonly bookData: FullDocument;

    private downloadsCount = 0;

    @Getter('Users/CHECK_IS_DOC_CHOSEN_BY_USER')
    isChosen: (DocId: string) => boolean;

    @Action('Users/CLICK_ON_DOCUMENT_CHOSE_BTN')
    storeToggleChose: (DocId: string) => boolean;

    get basicUrl(): string {
        return process.env.VUE_APP_STATIC_URL;
    }

    get downloads(): number {
        console.log(this.downloadsCount, this.bookData.downloads);
        return this.downloadsCount + this.bookData.downloads;
    }

    get url(): string {
        try {
            const serverDocName = this.bookData.document
                ? this.bookData.document.publicUrl.split('/').pop()
                : this.bookData.document_url.split('/').pop();

            return this.bookData.document && this.bookData.document.publicUrl
                ? `${this.basicUrl}/download?serverDocName=${serverDocName}&docName=${this.bookData.title}`
                : `/download?serverDocName=${serverDocName}&docName=${this.bookData.title}`;
        } catch (e) {
            return '';
        }
    }

    async downloadCounter(): Promise<void> {
        const res = await graphql<UpdateDocumentRequestType>(
            UpdateDocumentDownloads,
            {
                id: this.bookData.id,
                downloads: this.downloads + 1,
            }
        );
        console.log({ res });
    }
}
