









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'Poster' })
export default class Poster extends Vue {
    @Prop({ default: null }) readonly poster;
    private useFallBack = false;

    get basicUrl(): string {
        return process.env.VUE_APP_STATIC_URL;
    }

    get posterSrc(): string {
        if (!this.useFallBack) {
            return this.poster && this.poster.publicUrl
                ? `${this.basicUrl}${this.poster.publicUrl}`
                : require('../../assets/images/book-poster.jpg');
        } else {
            return require('../../assets/images/book-poster.jpg');
        }
    }
}
