


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    defaultFullDocument,
    FullDocument,
} from '@/types/front-data-types/full-docs';
import { Action, Getter } from 'vuex-class';

import ArButtonDownload from '@/components/form/ar-button-download.vue';
import ArButtonDocumentChoose from '@/components/form/ar-button-document-choose.vue';
import { getDate } from '@/helpers/date';

@Component({
    name: 'FullDoc',
    components: {
        ArButtonDocumentChoose,
        ArButtonDownload,
    },
})
export default class FullDoc extends Vue {
    @Prop({ default: defaultFullDocument }) readonly docData: FullDocument;
    @Prop({ default: false }) readonly isRead: boolean;
    @Prop({ default: false }) readonly noProjectName: boolean;
    @Prop({ default: false }) readonly noMaterialName: boolean;

    @Getter('Search/SEARCH_VALUE')
    private SEARCH_VALUE: string;
    @Getter('Search/IS_SEARCH_TAG')
    private IS_SEARCH_TAG: string;
    @Getter('Users/CHECK_IS_DOC_CHOSEN_BY_USER')
    isChosen: (DocId: string) => boolean;

    @Action('Users/CLICK_ON_DOCUMENT_CHOSE_BTN')
    storeToggleChose: (DocId: string) => boolean;

    private get docUrl(): string {
        const serverDocName = this.docData.document
            ? this.docData.document.publicUrl.split('/').pop()
            : this.docData.document_url.split('/').pop();
        return this.docData.document && this.docData.document.publicUrl
            ? `${process.env.VUE_APP_STATIC_URL}/download?serverDocName=${serverDocName}&docName=${this.docData.title}`
            : `/download?serverDocName=${serverDocName}&docName=${this.docData.title}`;
    }

    private get createdAt(): string {
        return this.docData.static_created_date
            ? getDate(this.docData.static_created_date)
            : getDate(this.docData.created_at);
    }

    private get description(): string {
        if (this.isRead) {
            return this.makeSearchBold(this.docData.fullText);
        }
        return this.makeSearchBold(this.docData.description);
    }

    private get title(): string {
        return this.makeSearchBold(this.docData.title);
    }

    private get languages(): string {
        let lang: string[] = [];
        if (
            this.docData &&
            this.docData.language &&
            this.docData.language.name
        ) {
            lang.push(this.docData.language.name);
        }
        if (
            this.docData &&
            this.docData.additional_language &&
            this.docData.additional_language.name
        ) {
            lang.push(this.docData.additional_language.name);
        }
        return lang.join(', ');
    }

    private capitalizeFirstLetter(str: string): string {
        return str ? str[0].toUpperCase() + str.slice(1) : '';
    }

    private makeSearchBold(str: string): string {
        const result = str;
        const searchValue = this.IS_SEARCH_TAG
            ? this.SEARCH_VALUE.slice(1)
            : this.SEARCH_VALUE;
        const search1 = searchValue.toLocaleLowerCase();
        const search2 = searchValue.toLocaleUpperCase();
        const search3 = searchValue;
        const search4 = this.capitalizeFirstLetter(searchValue);
        if (search3 !== '') {
            return result
                .split(search1)
                .join(`<strong>${search1}</strong>`)
                .split(search2)
                .join(`<strong>${search2}</strong>`)
                .split(search3)
                .join(`<strong>${search3}</strong>`)
                .split(search4)
                .join(`<strong>${search4}</strong>`);
        }
        return result;
    }
}
