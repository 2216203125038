import { FileType } from '@/types/front-data-types/base/file';

export type Book = {
    id?: string;
    title: string;
    author: string;
    year: string;
    image?: FileType;
    document?: FileType;
    downloads: number;
    chosenByUsers: { id: string }[];
};

export type BookList = Book[];

const defaultBook: Book = {
    title: '',
    author: '',
    year: '',
    downloads: 0,
    chosenByUsers: [],
};

const defaultBookList: BookList = [defaultBook];

export { defaultBook, defaultBookList };
