var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.isSimplifyVisual &&
        _vm.document &&
        _vm.document.extraDocs &&
        _vm.document.extraDocs.length !== 0
    )?_c('button',{staticClass:"arButtonDownload_simplify",on:{"click":_vm.downloadCounter}},[_c('img',{staticClass:"arButtonDownload_simplify-icon",attrs:{"src":require("@/assets/icons/list-download-blue.svg"),"alt":"Download icon"}}),_vm._v(" "+_vm._s(_vm.isBookCard ? '' : 'Скачати')+" ")]):(
        !_vm.isSimplifyVisual &&
        _vm.document &&
        _vm.document.extraDocs &&
        _vm.document.extraDocs.length !== 0
    )?_c('button',{staticClass:"arButtonDownload",on:{"click":_vm.downloadCounter}},[_vm._v(" Скачати "),_c('svg',{staticClass:"arButtonDownload-icon",attrs:{"width":"11","height":"12","viewBox":"0 0 11 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 4.5303L5.5303 8.71212L10.0606 4.5303","stroke":"#4383FF"}}),_c('path',{attrs:{"d":"M5.53027 8.71212L5.53027 0","stroke":"#4383FF"}}),_c('path',{attrs:{"d":"M0.999941 11.5L10.0605 11.5","stroke":"#4383FF"}})])]):(_vm.isSimplifyVisual && _vm.url && _vm.url.length > 0)?_c('a',{staticClass:"arButtonDownload_simplify",attrs:{"href":_vm.url,"target":"_blank"},on:{"click":_vm.downloadCounter}},[_c('img',{staticClass:"arButtonDownload_simplify-icon",attrs:{"src":require("@/assets/icons/list-download-blue.svg"),"alt":"Download icon"}}),_vm._v(" "+_vm._s(_vm.isBookCard ? '' : 'Скачати')+" ")]):_c('a',{staticClass:"arButtonDownload",attrs:{"href":_vm.url,"target":"_blank"},on:{"click":_vm.downloadCounter}},[_vm._v(" Скачати "),_c('svg',{staticClass:"arButtonDownload-icon",attrs:{"width":"11","height":"12","viewBox":"0 0 11 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 4.5303L5.5303 8.71212L10.0606 4.5303","stroke":"#4383FF"}}),_c('path',{attrs:{"d":"M5.53027 8.71212L5.53027 0","stroke":"#4383FF"}}),_c('path',{attrs:{"d":"M0.999941 11.5L10.0605 11.5","stroke":"#4383FF"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }